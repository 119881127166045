
/**
 * GLightbox
 * Awesome pure javascript lightbox
 * made by https://www.biati.digital
 * Github: https://github.com/biati-digital/glightbox
 */

import $ from 'jquery';
import GLightbox from 'glightbox';

// Initial Lightbox instance
var lightbox = GLightbox({
  selector: '.gallery .glightbox',
  touchNavigation: true,
  loop: true,
  autoplayVideos: true,
  moreLength: 0
});

// Reload the lightbox, after inserting content with ajax.
$(document).ajaxSuccess(function () {
  lightbox.reload();
});
