import $ from 'jquery';

// Remove transitions while page is loading and being resized
var resizeTimer;

$(document).ready(function () {
  setTimeout(function () {
    $('body').removeClass('no-transitions');
  }, 300);
});

$(window).on('resize', function (e) {
  clearTimeout(resizeTimer);
  $('body').addClass('no-transitions');

  resizeTimer = setTimeout(function () {
    $('body').removeClass('no-transitions');
  }, 250);
});
