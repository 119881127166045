import $ from 'jquery';

// sidebar dropdown trigger
var holder = $('.sidebar');
$('.sidebar').on('click', '.sidebar__opener', function (e) {
  e.stopPropagation();
  $(this).toggleClass('active');
  holder.toggleClass('active');
  $('.service-list').slideToggle('fast');
});

$('.service-list__link--active');

$(function () {
  var activeServiceTitle = $('.service-list__link--active').html();
  $('.sidebar__service-title').html(activeServiceTitle);
});
