import $ from 'jquery';

// class is add/remover on focus
$('.search-bar__input').focus(function () {
  if ($(this).val().length == 0) {
    $(this).closest('.search-bar').addClass('popular-search-active');
  } else {
    $(this).closest('.search-bar').removeClass('popular-search-active');
  }
});

// class is add/remover on keyup
$('.search-bar__input').keyup(function () {
  if ($(this).val().length == 0) {
    $(this).closest('.search-bar').addClass('popular-search-active')
  }
  if ($(this).val().length > 0) {
    $(this).closest('.search-bar').removeClass('popular-search-active')
  }
});

$('.search-bar__input').blur(function () {
  if ($(this).val()) {
    $(this).addClass('background-active');
  } else {
    $(this).removeClass('background-active');
  }
});

$('.page-header').focusout(function () {
  setTimeout(function(){
    $('.search-bar').removeClass('popular-search-active');
  }, 500);
});

// form submit is disable in Enter key
$('.search-bar').on('keyup keypress', function (e) {
  var keyCode = e.keyCode || e.which;
  if (keyCode === 13) {
    e.preventDefault();
    return false;
  }
});

//Text carousel
$(function () {
  var wordsArray = [];
  $('.popular-search__list li').each(function () {
    wordsArray.push($(this).text());
  });
  var count = 0;
  setInterval(function () {
    count++;
    $('#text-carousel').fadeOut(400, function () {
      $(this).text(wordsArray[count % wordsArray.length]).fadeIn(400);
    });
  }, 3000);
});
