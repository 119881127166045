import $ from 'jquery';
var Flickity = require('flickity');

// banner carousel
if ($('.js-carousel').length) {
  var flkty = new Flickity('.js-carousel', {
    // options...
    adaptiveHeight: true,
    prevNextButtons: false,
    setGallerySize: true,
    prevNextButtons: true,
    pageDots: false,
    autoPlay: false,
    wrapAround: true,
    cellAlign: 'left',
    imagesLoaded: true,
    setGallerySize: false
  });
}
