import $ from 'jquery';
import './resize';
import './burger-menu';
import './nav-dropdown';
import './carousel';
import './accordian';
import './nonce';
import './loadmore';
import './search-bar';
import './filter-dropdown';
import './sidebar-dropdown';
import './glightbox';
import './hero-fadein';

// add iframe wrapper inside WYSIWYG editor
$(function () {
  $('.editor iframe').wrap('<div class="editor__iframe-wrapper"></div>');
});
