export default {
  init() {
    // JavaScript to be fired on all pages
    // console.log('common');
  },
  finalize() {
    // console.log('common finalize');
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
