import $ from 'jquery';

// fiter dropdown trigger
$('.filter').on('click', '.filter__opener', function (e) {
  e.stopPropagation();
  $(this).toggleClass('active');
  $('.filter-dropdown').slideToggle('fast');
});

$('.filter').on('click', '.filter-dropdown__link', function (e) {
  e.preventDefault();
  $(this).toggleClass('active');
  $('.filter-dropdown').slideToggle('fast');

  $('.filter__opener').toggleClass('active');
  var text = $('.filter-dropdown__link-text', this).html();
  $('.image-type').html(text);
});

$('.filter').on('click', '.filter-dropdown__item', function () {
  $(this).addClass('active');
  $(this).siblings().removeClass('active');
});

$('body').on('click', function () {
  $('.filter-dropdown').slideUp('fast');
  $('.filter__opener').removeClass('active');
});

/**
 * Ajax filtering on posts.
 * Load posts with selected filter on click.
 */
$(document).on("click", ".filter-dropdown li a", function (e) {
  e.preventDefault();

  /**
   * First make sure we have set the nonce
   *
   * @var {string}
   */
  var nonce_value = $(this)
    .parent()
    .parent()
    .find(".js-nonce")
    .val();

  /**
   * Action name
   *
   * @var {string}
   */
  var action = $(".js-nonce").attr('data-action');

  /**
   * If nonce is empty stop script
   */
  if (!nonce_value) {
    return;
  }

  /**
   * Term name
   *
   * @var {string}
   */

  // Add css class loading
  var me = $('.filter__container');
  me.addClass("loading");

  // update term on loadmore button
  if ($(".button--loadmore").length) {
    $(".button--loadmore").attr("data-image-gallery-term", $(this).attr("data-image-gallery-term"));
    var posts = $(".button--loadmore").attr("data-posts");
    $(".button--loadmore").attr("data-postsloaded", posts);
  }

  $.ajax({
    type: "POST",
    url: ajaxurl,
    data: {
      action: action,
      term: $(this).attr("data-image-gallery-term"),
      nonce: $(this)
        .closest(".filter-dropdown")
        .find("input[name=_wpnonce]")
        .val(),
      no_posts: $('.button--loadmore').attr("data-posts"),
    },
    success: function (response) {
      /* Put response in this element */
      me.removeClass("loading");

      if (action && action == 'image_filter_terms') {
        $(".image-gallery__row").html(response.html);

        // Hide load more button if no more posts
        if (response.show_load_more == false) {
          $('.button--loadmore').hide();
        } else {
          $('.button--loadmore').show();
        }
      }

      return false;
    },
    error: function (error) {
      me.removeClass("loading");
    }
  });
});
